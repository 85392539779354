/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    h3: "h3",
    pre: "pre",
    code: "code",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Related"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/notes/ssh-catch"
  }, "general ssh notes")), "\n", React.createElement(_components.h2, null, "Git Branching Strategies"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.gitkraken.com/learn/git/best-practices/git-branch-strategy"
  }, "Git Branch Strategy - gitkraken")), "\n", React.createElement(_components.h2, null, "CheatSheet"), "\n", React.createElement(_components.h3, null, "Check Git Configurations"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git config --global --list\ngit config --local --list\ngit config --system --list\n")), "\n", React.createElement(_components.h3, null, "Get Remote Origin Url"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "git config --get remote.origin.url\n")), "\n", React.createElement(_components.h2, null, "Reference for Common Challenge and Solutions"), "\n", React.createElement(_components.h3, null, "Solving Git Ignoring GitIgnore"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "git rm -r --cached .\ngit add .\ngit commit -m \"fixed untracked files\"\n\n")), "\n", React.createElement(_components.h3, null, "Revert a Committed Change with reset HEAD"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://devconnected.com/how-to-git-reset-to-head"
  }, "How to Git Reset to HEAD - devconnected")), "\n", React.createElement(_components.p, null, "Go back to ", React.createElement(_components.code, null, "HEAD")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git reset --hard HEAD\n")), "\n", React.createElement(_components.p, null, "Go back to commit before ", React.createElement(_components.code, null, "HEAD")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git reset --hard HEAD^\ngit reset --hard HEAD~1\n")), "\n", React.createElement(_components.p, null, "Go back two commits before ", React.createElement(_components.code, null, "HEAD")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git reset --hard HEAD~2\n")), "\n", React.createElement(_components.h3, null, "Delete Local and Remote Branches"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "// delete branch locally\ngit branch -d localBranchName\n\n// delete branch remotely\ngit push origin --delete remoteBranchName\n")), "\n", React.createElement(_components.h2, null, "Multi User Challenge"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git credential --help\n")), "\n", React.createElement(_components.h3, null, "CheatSheet"), "\n", React.createElement(_components.h4, null, "Merge Priority"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "theirs"), " or ", React.createElement(_components.code, null, "ours")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git merge --strategy-option theirs <feature-branch> staging\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "git merge -X theirs staging <bug-fix>\n")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.atlassian.com/git/tutorials/"
  }, "Atlassian git tutorials")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
